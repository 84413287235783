import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function Contato(){
  const [formData, setFormData] = useState({
    assunto: '',
    nome: '',
    email: '',
    mensagem: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { assunto, nome, email, mensagem } = formData;

    if (!assunto || !nome || !email || !mensagem) {      
      return;
    }

    const mailtoLink = `mailto:seuemail@exemplo.com?subject=${encodeURIComponent(assunto)}&body=${encodeURIComponent(
        `Nome: ${nome}\nEmail: ${email}\n\nMensagem:\n${mensagem}`
      )}`;

      window.location.href = mailtoLink;

    setFormData({
      assunto: '',
      nome: '',
      email: '',
      mensagem: ''
    });
  };

  return (
    <>
    <Header/>
    <div className="login-section">
      <div className="login-form">
        <div className="text-center mb-4">
          <h2 className="text-2xl font-bold text-gray-700 mb-4 text-center">
            Formulário de Contato
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="assunto" className="form-label">
              Assunto
            </label>
            <input
              type="text"
              id="assunto"
              name="assunto"
              className="form-control"
              placeholder="Digite o assunto"
              value={formData.assunto}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nome" className="form-label">
              Nome
            </label>
            <input
              type="text"
              id="nome"
              name="nome"
              className="form-control"
              placeholder="Digite seu nome"
              value={formData.nome}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="form-control"
              placeholder="Digite seu email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mensagem" className="form-label">
              Mensagem
            </label>
            <textarea
              id="mensagem"
              name="mensagem"
              className="form-control"
              rows="4"
              placeholder="Digite sua mensagem em até 500 caracteres"
              value={formData.mensagem}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-success w-100">
            Enviar
          </button>
        </form>
      </div>
    </div>
    <Footer/>
    </>

  );
};
