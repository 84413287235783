import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.css';
import AlertMessage from '../../components/AlertMessage';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { redefinirSenha } from '../../services/credenciaisService';

export default function RecuperarSenha() {
    const { token } = useParams(); // Pegando o token da URL
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setAlertMessage({
                title: 'Erro',
                message: 'As senhas não coincidem',
                severity: 'error'
            });
            return;
        }

        setLoading(true);
        try {
            await redefinirSenha(token, password);
            setAlertMessage({
                title: 'Sucesso',
                message: 'Senha alterada com sucesso!',
                severity: 'success'
            });
            setTimeout(() => {
                navigate('/entrar');
            }, 2000);
        } catch (error) {
            setAlertMessage({
                title: 'Erro',
                message: error?.message || 'Erro ao alterar a senha',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <div className="recuperar-senha-container bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card shadow-lg my-5">
                                <div className="card-body">
                                    <h1 className="card-title">Recuperar Senha</h1>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="password">Nova Senha</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="confirmPassword">Confirmar Nova Senha</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="confirmPassword"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                            {loading ? 'Alterando...' : 'Alterar Senha'}
                                        </button>
                                    </form>
                                    {alertMessage && (
                                        <AlertMessage title={alertMessage.title} message={alertMessage.message} severity={alertMessage.severity} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
