import React, { useEffect, useState } from 'react';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { createUsuario } from "../../services/credenciaisService";
import { getCursos } from '../../services/cursoService';
import { listEstados } from '../../services/estadoService';
import { listCidadesById } from '../../services/cidadeService';
import { getUniversidades } from '../../services/universidadeService';
import AlertMessage from '../../components/AlertMessage';
import './styles.css';

export default function CriarConta() {    
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [usuarioAcesso, setUsuarioAcesso] = useState('');
    const [genero, setGenero] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [telefone, setTelefone] = useState('');
    const [estadoId, setEstadoId] = useState(null);
    const [estados, setEstados] = useState(null);
    const [cidades, setCidades] = useState(null);
    const [cidadeId, setCidadeId] = useState(null);
    const [universidades, setUniversidades] = useState(null);
    const [universidadeId, setUniversidadeId] = useState(null);
    const [cursoId, setCursoId] = useState(null);
    const [cursos, setCursos] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [loading, setLoading] = useState(false);



    const handleSubmit = async (event) => {
        event.preventDefault();

        if (senha !== confirmarSenha) {
            setAlertMessage({
                title: 'Erro',
                message: 'As senhas não coincidem',
            });
            return;
        }

        const usuario = {
            nome,
            email,
            senha,
            usuarioAcesso,
            genero,
            dataNascimento: new Date(dataNascimento),
            telefone,
            cidadeId,
            universidadeId,
            cursoId,
        };

        try {
            setLoading(true);
            await createUsuario(usuario);

            setAlertMessage({
                title: 'Sucesso',
                message: 'Conta criada com sucesso!',
                severity: 'success'
            });
            setTimeout(() => {
                window.location.href = '/entrar'
            }, 3000);
        } catch (error) {
            console.log(error);
            setAlertMessage({
                title: 'Erro',
                message: error?.message || 'Erro na criação de conta',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            const [cursosData, estadosData, universidadesData] = await Promise.all([
                getCursos(),
                listEstados(),
                getUniversidades()
            ]);
            setCursos(cursosData);
            setEstados(estadosData);
            setUniversidades(universidadesData);
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (estadoId) {
            async function fetchCidades() {
                const cidadesData = await listCidadesById(estadoId);
                setCidades(cidadesData);
            }
            fetchCidades();
        }
    }, [estadoId]);

    return (
        <>
            <Header />
            <div className="bg-gray-100 text-gray-800 m-4">
                {alertMessage?.title && (<AlertMessage title={alertMessage?.title} message={alertMessage?.message} severity={alertMessage?.severity} />
                )}
                <div className="login-section">
                    <div className="login-form">
                        <div className="text-center mb-4">
                            <h2 className="text-2xl font-bold text-gray-700 mb-4 text-center">Criar Conta</h2>
                        </div>
                        <form id="signupForm" onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="nome" className="form-label">Nome</label>
                                <input
                                    type="text"
                                    id="nome"
                                    name="nome"
                                    className="form-control"
                                    placeholder="Digite seu nome"
                                    value={nome}
                                    onChange={(e) => setNome(e?.target?.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="usuario" className="form-label">Nome de usuário</label>
                                <input
                                    type="text"
                                    id="usuario"
                                    name="usuario"
                                    className="form-control"
                                    placeholder="Digite seu nome de usuário"
                                    value={usuarioAcesso}
                                    onChange={(e) => setUsuarioAcesso(e?.target?.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Digite seu email"
                                    value={email}
                                    onChange={(e) => setEmail(e?.target?.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="senha" className="form-label">Senha</label>
                                <input
                                    type="password"
                                    id="senha"
                                    name="senha"
                                    className="form-control"
                                    placeholder="Digite sua senha"
                                    value={senha}
                                    onChange={(e) => setSenha(e?.target?.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="confirmarSenha" className="form-label">Confirmar Senha</label>
                                <input
                                    type="password"
                                    id="confirmarSenha"
                                    name="confirmarSenha"
                                    className="form-control"
                                    placeholder="Confirme sua senha"
                                    value={confirmarSenha}
                                    onChange={(e) => setConfirmarSenha(e?.target?.value)}
                                    required
                                />
                            </div>

                            {/* Gênero */}
                            <div className="mb-4">
                                <label htmlFor="genero" className="form-label">Gênero</label>
                                <select
                                    id="genero"
                                    className="form-select"
                                    value={genero}
                                    onChange={(e) => setGenero(e?.target?.value)}
                                    required
                                >
                                    <option value="">Selecione seu gênero</option>
                                    <option value="Masculino">Masculino</option>
                                    <option value="Feminino">Feminino</option>
                                    <option value="Não Binário">Não Binário</option>
                                    <option value="Prefiro não dizer">Prefiro não dizer</option>
                                </select>
                            </div>

                            {/* Data de Nascimento */}
                            <div className="mb-4">
                                <label htmlFor="dataNascimento" className="form-label">Data de Nascimento</label>
                                <input
                                    type="date"
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    className="form-control"
                                    value={dataNascimento}
                                    onChange={(e) => setDataNascimento(e?.target?.value)}
                                    required
                                />
                            </div>

                            {/* Telefone */}
                            <div className="mb-4">
                                <label htmlFor="telefone" className="form-label">Telefone</label>
                                <input
                                    type="tel"
                                    id="telefone"
                                    name="telefone"
                                    className="form-control"
                                    placeholder="Digite seu telefone"
                                    value={telefone}
                                    onChange={(e) => setTelefone(e?.target?.value)}
                                    required
                                />
                            </div>

                            {/* Estados */}
                            <div className="mb-4">
                                <label htmlFor="estado" className="form-label">Estado</label>
                                <select
                                    id="estado"
                                    className="form-select"
                                    value={estadoId}
                                    onChange={(e) => setEstadoId(e?.target?.value)}
                                    required
                                >
                                    <option value="">Selecione seu estado</option>
                                    {estados?.map((estado) => (
                                        <option key={estado.id} value={estado?.id}>{estado?.nome}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Cidades (habilitado somente se um estado estiver selecionado) */}
                            <div className="mb-4">
                                <label htmlFor="cidade" className="form-label">Cidade</label>
                                <select
                                    id="cidade"
                                    className="form-select"
                                    value={cidadeId}
                                    onChange={(e) => setCidadeId(e?.target?.value)}
                                    disabled={!estadoId}
                                    required
                                >
                                    <option value="">Selecione sua cidade</option>
                                    {cidades?.map((cidade) => (
                                        <option key={cidade?.id} value={cidade?.id}>{cidade?.nome}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Universidades */}
                            <div className="mb-4">
                                <label htmlFor="universidade" className="form-label">Universidade</label>
                                <select
                                    id="universidade"
                                    className="form-select"
                                    value={universidadeId}
                                    onChange={(e) => setUniversidadeId(e?.target?.value)}
                                    required
                                >
                                    <option value="">Selecione sua universidade</option>
                                    {universidades?.map((universidade) => (
                                        <option key={universidade?.id} value={universidade?.id}>{universidade?.nome}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Cursos */}
                            <div className="mb-4">
                                <label htmlFor="curso" className="form-label">Curso</label>
                                <select
                                    id="curso"
                                    className="form-select"
                                    value={cursoId}
                                    onChange={(e) => setCursoId(e?.target?.value)}
                                    required
                                >
                                    <option value="">Selecione seu curso</option>
                                    {cursos?.map((curso) => (
                                        <option key={curso?.id} value={curso?.id}>{curso?.nome}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="text-center mb-4">
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading ? 'Criando Conta...' : 'Criar Conta'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
