import React, { useState, useEffect, useCallback } from 'react';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { details, editUsuario } from "../../services/credenciaisService";
import { listEstados } from '../../services/estadoService';
import { listCidadesById } from '../../services/cidadeService';
import AlertMessage from '../../components/AlertMessage';

export default function Perfil() {
    const [formData, setFormData] = useState({
        id: 0,
        nome: '',
        mainGender: '',
        dob: '',
        state: '',
        cidade: '',
        username: '',
        email: '',
        telefone: ''
    });

    const [loading, setLoading] = useState(true);
    const [alertMessage, setAlertMessage] = useState(null);
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);

    const loadUserProfile = useCallback(async () => {
        try {
            setLoading(true);
            const userDetails = await details();
            const estadosResponse = await listEstados();
            
            setFormData({
                id: userDetails?.id,
                nome: userDetails?.nome,
                mainGender: userDetails?.genero,
                dob: new Date(userDetails.dataNascimento)?.toISOString()?.split('T')[0],
                state: userDetails.cidade?.estado?.id || '',
                cidade: userDetails?.cidade?.id,
                username: userDetails?.usuarioAcesso,
                email: userDetails?.email,
                telefone: userDetails?.telefone
            });
            
            setEstados(estadosResponse);
                        
            if (userDetails.cidade?.estado?.id) {
                const cidadesResponse = await listCidadesById(userDetails?.cidade?.estado?.id);
                setCidades(cidadesResponse);
            }
        } catch (error) {
            setAlertMessage({
                title: 'Erro',
                message: error?.message || 'Erro ao carregar dados da conta',
            });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadUserProfile();
    }, [loadUserProfile]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleStateChange = async (e) => {
        const stateId = e.target.value;
        setFormData({
            ...formData,
            state: stateId,
            cidade: ''
        });

        const cidadesResponse = await listCidadesById(stateId);
        setCidades(cidadesResponse);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { id, nome, mainGender, dob, state, cidade, username, email, telefone } = formData;

        if (!nome || !mainGender || !dob || !state || !cidade || !username || !email || !telefone) {
            setAlertMessage({ message: 'Por favor, preencha todos os campos.' });
            return;
        }

        const usuario = {
            Id: id,
            nome,
            genero: mainGender,
            dataNascimento: dob,
            cidadeId: cidade,
            estadoId: state,
            usuarioAcesso: username,
            email,
            telefone,
        };

        try {
            if (formData.id) {
                await editUsuario(formData.id, usuario);
                setAlertMessage({ title: 'Sucesso!',message: 'Usuário editado com sucesso!', severity: 'success' });
            }
        } catch (error) {
            setAlertMessage({
                title: 'Erro',
                message: error?.message || 'Erro ao editar dados da conta',
            });
        }
    };

    if (loading) return <div>Loading...</div>;

    return (
        <>
            <Header />
            {alertMessage?.title && (
                <AlertMessage title={alertMessage?.title} message={alertMessage?.message} severity={alertMessage?.severity} />
            )}
            <div className="profile-section d-flex flex-column align-items-center justify-content-center m-4">
                <div className="profile-card container-lg p-4 shadow-lg rounded" style={{ maxWidth: '800px', width: '100%' }}>
                    <div className="text-center mb-4">
                        <h2 className="text-2xl font-bold text-gray-700 mb-4 text-center">Perfil</h2>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="nome" className="form-label">Nome</label>
                            <input
                                type="text"
                                id="nome"
                                name="nome"
                                className="form-control"
                                placeholder="Digite seu nome"
                                value={formData.nome}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="mainGender" className="form-label">Gênero</label>
                            <select
                                id="mainGender"
                                name="mainGender"
                                className="form-select"
                                value={formData.mainGender}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Selecione seu gênero</option>
                                <option value="Masculino">Masculino</option>
                                <option value="Feminino">Feminino</option>
                                <option value="Não-binário">Não-binário</option>
                                <option value="Prefiro não dizer">Prefiro não dizer</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="dob" className="form-label">Data de Nascimento</label>
                            <input
                                type="date"
                                id="dob"
                                name="dob"
                                className="form-control"
                                value={formData.dob}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="state" className="form-label">Estado</label>
                            <select
                                id="state"
                                name="state"
                                className="form-select"
                                value={formData.state}
                                onChange={handleStateChange}
                                required
                            >
                                <option value="">Selecione seu estado</option>
                                {estados?.map((estado) => (
                                    <option key={estado?.id} value={estado?.id}>{estado?.nome}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="cidade" className="form-label">Cidade</label>
                            <select
                                id="cidade"
                                name="cidade"
                                className="form-select"
                                value={formData.cidade}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Selecione sua cidade</option>
                                {cidades?.map((cidade) => (
                                    <option key={cidade?.id} value={cidade?.id}>{cidade?.nome}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="username" className="form-label">Usuário</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                className="form-control"
                                placeholder="Digite seu usuário em até 15 caracteres. Ex: maria123"
                                value={formData.username}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                disabled
                                className="form-control"
                                placeholder="Digite seu email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="telefone" className="form-label">Telefone</label>
                            <input
                                type="tel"
                                id="telefone"
                                name="telefone"
                                className="form-control"
                                placeholder="Digite seu telefone"
                                value={formData.telefone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">Atualizar Perfil</button>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}
