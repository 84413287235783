import './App.css';
import Router from './components/Routing';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';

function App() {
  return (
    <BrowserRouter>
    <UserProvider>
      <Router />
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
