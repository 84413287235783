import { api } from './axiosConfig';

export const listTipoOportunidades = async () => {
  try {
    const { data } = await api.get('/TipoOportunidade');
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
