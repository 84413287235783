import React, { useState } from 'react';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import './styles.css';
import AlertMessage from '../../components/AlertMessage';
import { login } from "../../services/credenciaisService";
import { useLocation, useNavigate } from 'react-router-dom';

export default function Login() {
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const redirect = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get('redirect') || '/oportunidades';

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            setLoading(true);
            await login(email, senha);
            setAlertMessage({
                title: 'Sucesso',
                message: 'Login realizado com sucesso!',
                severity: 'success'
            });
            redirect(redirectUrl);
            window.location.reload();
        } catch (error) {
            console.log(error);
            setAlertMessage({
                title: 'Erro',
                message: error?.message || 'Erro no login. Verifique suas credenciais.',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <div className="bg-gray-100 text-gray-800">
                {alertMessage?.title && (
                    <AlertMessage
                        title={alertMessage?.title}
                        message={alertMessage?.message}
                        severity={alertMessage?.severity}
                    />
                )}
                <div className="login-section">
                    <div className="login-form">
                        <div className="text-center mb-4">
                            <h2 className="text-2xl font-bold text-gray-700 mb-4 text-center">Entrar</h2>
                        </div>
                        {/* Formulário de login */}
                        <form id="loginForm" onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Digite seu email"
                                    value={email}
                                    onChange={(e) => setEmail(e?.target?.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="senha" className="form-label">Senha</label>
                                <input
                                    type="password"
                                    id="senha"
                                    name="senha"
                                    className="form-control"
                                    placeholder="Digite sua senha"
                                    value={senha}
                                    onChange={(e) => setSenha(e?.target?.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-success w-100">
                                {loading ? 'Carregando...' : 'Entrar'}
                            </button>
                            <div className="text-center mt-4">
                            <p>
                                Não tem uma conta?{' '}
                                <button 
                                    className="btn btn-link" 
                                    onClick={() => redirect('/criar-conta')}
                                >
                                    Crie uma agora!
                                </button>
                            </p>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
