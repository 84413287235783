import './styles.css';
import React, { useState } from 'react';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { resetSenha } from '../../services/credenciaisService';
import AlertMessage from '../../components/AlertMessage';

export default function EsqueciMinhaSenha() {
    const [identifier, setIdentifier] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    function clearAlert() {
        setAlertMessage({})
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        clearAlert();

        if (!identifier) {
            setAlertMessage({
                title: 'Erro',
                message: 'Por favor, preencha o campo de email ou nome de usuário.',
            });
            return;
        }

        const payload = { email: identifier };

        try {
            setLoading(true);


            await resetSenha(payload);


            setAlertMessage({
                title: 'Sucesso',
                message: 'Link de recuperação enviado com sucesso!',
                severity: 'success',
            });
        } catch (error) {
            setAlertMessage({
                title: 'Erro',
                message: error?.message || 'Ocorreu um erro ao enviar o link de recuperação.',
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <Header />
            <div className="reset-password-section">
                <div className="reset-password-form">
                    {alertMessage?.title && <AlertMessage title={alertMessage?.title} message={alertMessage?.message} severity={alertMessage?.severity} />
                    }
                    <div className="text-center mb-4">
                        <h2 className="text-2xl font-bold text-gray-700 mb-4 text-center">Esqueci Minha Senha</h2>
                    </div>
                    <form id="resetPasswordForm" onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="identifier" className="form-label">Email ou Nome de Usuário</label>
                            <input
                                type="text"
                                id="identifier"
                                name="identifier"
                                className="form-control"
                                placeholder="Digite seu email ou nome de usuário"
                                value={identifier}
                                onChange={(e) => setIdentifier(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-success w-100" disabled={loading}>
                            {loading ? 'Enviando...' : 'Enviar Link de Recuperação'}
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}
