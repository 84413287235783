import Footer from "../../components/Footer";
import Header from "../../components/Header";
import './styles.css'

export default function Home() {
    return (
        <>
            <Header />
            <body>
                <section className="main-content">
                    <div className="container">
                        <h1>Inex</h1>                                       
                        <p>Aqui você encontra Cursos de Extensão e Iniciação Científica em um só lugar.</p>
                        <a href="/oportunidades" className="btn-login">Ver Oportunidades</a>
                    </div>
                </section>
            </body>
            <Footer />
        </>
    )
}