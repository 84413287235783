import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './styles.css';
import { deleteUsuario, logout } from "../../services/credenciaisService";
import useUser from '../../hooks/useUser';
import AlertMessage from '../../components/AlertMessage';

export default function ApagarConta() {
    const { userDetails } = useUser();
    const [alertMessage, setAlertMessage] = useState(null);

    const [confirmation, setConfirmation] = useState('');    

    const handleInputChange = (e) => {
        setConfirmation(e.target.value);
    };

    const handleSubmit = async (e) => {
        try {
        e.preventDefault();
        await deleteUsuario(userDetails.id);

        setAlertMessage({
            title: 'Sucesso',
            message: 'Conta apagada com sucesso!',
            severity: 'success'
        });
        logout();
    } catch (error) {
        console.log(error);
        setAlertMessage({
            title: 'Erro',
            message: error?.message || 'Erro ao deletar conta.',
            severity: 'error'
        });
    };
}

    return (
        <>
            <Header />
            <div className="delete-account-section">
            {alertMessage?.title && (
                    <AlertMessage
                        title={alertMessage?.title}
                        message={alertMessage?.message}
                        severity={alertMessage?.severity}
                    />
                )}
                <div className="delete-account-form">
                    <h2 className="text-2xl font-bold text-gray-700 mb-4">Apagar Conta</h2>                   
                    <form id="deleteAccountForm" onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="confirmation" className="form-label">
                                Para confirmar, digite "APAGAR" abaixo:
                            </label>
                            <input
                                type="text"
                                id="confirmation"
                                name="confirmation"
                                className="form-control"
                                placeholder="Digite 'APAGAR'"
                                value={confirmation}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-danger w-100">
                            Apagar Conta
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}
