import { api } from './axiosConfig';

export const getUniversidades = async () => {
  try {
    const { data } = await api.get('/Universidade', {
      headers: {
        'Content-Type': 'application/json',
        'accept': '*/*'
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data || 'Ocorreu um erro ao tentar obter as universidades';
  }
};
