import './styles.css';
import useUser from '../../hooks/useUser';
import { expiredSession } from '../../services/axiosConfig';

export default function Header() {

    const { isLogged } = useUser();

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container d-flex justify-content-between">
                <a className="navbar-brand" href="/">📝</a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse navbar-nav custom-navbar-font" id="navbarNav">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Conta
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                {!isLogged && <li><a className="dropdown-item" href="/criar-conta">Criar Conta</a></li>}
                                {isLogged && <li><a className="dropdown-item" href="/perfil">Perfil</a></li>}
                                {!isLogged && <li><a className="dropdown-item" href="/entrar">Entrar</a></li>}
                                {!isLogged && <li><a className="dropdown-item" href="/esqueci">Esqueci Minha Senha</a></li>}
                                {isLogged && <li><a className="dropdown-item" onClick={() => { expiredSession(); window.location.href = '/oportunidades' }}>Sair</a></li>}
                                {isLogged && <li><a className="dropdown-item" href="/apagar-conta">Apagar Conta</a></li>}
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownOportunidades" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Oportunidades
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownOportunidades">
                                <li><a className="dropdown-item" href="/oportunidades?tipo_oportunidade=extensao">Cursos de Extensão</a></li>
                                <li><a className="dropdown-item" href="/oportunidades?tipo_oportunidade=iniciacao">Iniciação Científica</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/contato">Contato</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
