import axios, { HttpStatusCode } from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((request) => {
  const headers = request?.headers ?? {};
  const token = JSON.parse(localStorage.getItem('token'));

  if (token?.value && token?.type) {
    headers['Authorization'] = `${token.type} ${token.value}`;
  }

  request.headers = headers;

  return request;
});

export const expiredSession = async () => {

  localStorage.clear();
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === HttpStatusCode.Unauthorized) {
      await expiredSession();
    }

    return Promise.reject(error);
  }
);