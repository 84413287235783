'use client';
import React, { createContext, useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { details as getUserDetailsService } from '../services/credenciaisService';

const UserContext = createContext({
  userDetails: undefined,
  setUserDetails: () => {},
  isLogged: true,  
});

function UserProvider({ children }) {
  const [userDetails, setUserDetails] = useState();
  const [isLogged, setIsLogged] = useState(true);

  useLayoutEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userResponseData = await getUserDetailsService();
        setIsLogged(true);
        setUserDetails(userResponseData);
      } catch (error) {
        console.log('error');
        setUserDetails({});
        setIsLogged(false);
      }
    };

    fetchUserDetails();
  }, []);

  const providerValue = useMemo(
    () => ({
      userDetails,
      setUserDetails,
      isLogged,
    }),
    [userDetails, setUserDetails, isLogged],
  );

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
}

export { UserContext, UserProvider };
