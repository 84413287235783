import { api } from './axiosConfig';

export const createUsuario = async (usuario) => {
  try {
    const { data } = await api.post('/Usuario', usuario, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const editUsuario = async (id, usuario) => {
  try {
    const { data } = await api.put(`/Usuario/${id}`, usuario, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const deleteUsuario = async (id) => {
  try {
    const { data } = await api.delete(`/Usuario/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};


export const login = async (email, senha) => {
  const payload = {
    email,
    senha,
  }

  try {
    const { data } = await api.post(`/Credenciais/login`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });   
    const token = { value: data?.token, type: 'Bearer' };
    localStorage.setItem('token', JSON.stringify(token));

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const logout = () =>{
  localStorage.clear();
  window.location.href = '/oportunidades';
}

export const details = async () => {
  try {

    const { data } = await api.get('/Credenciais/detalhes', {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const resetSenha = async (payload) => {
  try {
    const { data } = await api.post('/Credenciais/esqueci-senha', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data || 'Ocorreu um erro ao tentar redefinir a senha';
  }
};

export const redefinirSenha = async (token, novaSenha) => {
  const payload = {
    token,
    novaSenha,
  };

  try {
    const { data } = await api.post('/Credenciais/redefinir-senha', payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data;
  } catch (error) {
    throw error?.response?.data || 'Ocorreu um erro ao tentar redefinir a senha';
  }
};
