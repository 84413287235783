import React, { useState } from 'react';

export default function AlertMessage({ title, message, severity = 'danger', onClose }) {
    const [isVisible, setIsVisible] = useState(true);

    const closeAlert = () => {
        setIsVisible(false);
        if (onClose) onClose();
    };

    if (!isVisible) return null;

    return (
        <div className={`card text-white bg-${severity} mb-3 m-4`}>
            <div className="card-header d-flex justify-content-between align-items-center">
                {severity}
                <button type="button" className="close" onClick={closeAlert} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="card-body">
                <h5 className="card-title">{title || 'Erro'}</h5>
                <p className="card-text">{message || 'Ocorreu um erro.'}</p>
            </div>
        </div>
    );
}
