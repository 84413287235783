import { Route, Routes } from "react-router-dom";
import Home from '../../pages/Home';
import Oportunidades from "../../pages/Oportunidades";
import CriarConta from "../../pages/CriarConta";
import EsqueciMinhaSenha from "../../pages/EsqueciMinhaSenha";
import Contato from "../../pages/Contato";
import Perfil from "../../pages/Perfil";
import Login from "../../pages/Entrar";
import ApagarConta from "../../pages/ApagarConta";
import OportunidadeDetalhe from "../../pages/OportunidadeDetalhe";
import PrivateRoute from "../PrivateRoute";
import RecuperarSenha from "../../pages/RecuperarSenha";

export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/oportunidades' element={<PrivateRoute element={<Oportunidades />} />} />
            <Route path='/oportunidades/:id'element={<PrivateRoute element={<OportunidadeDetalhe />} />}  />
            <Route path='/criar-conta' element={<CriarConta />} />
            <Route path='/esqueci' element={<EsqueciMinhaSenha />} />
            <Route path='/recuperar-senha/:token' element={<RecuperarSenha />} />
            <Route path='/contato' element={<Contato />} />
            <Route path='/perfil' element={<Perfil />} />
            <Route path="/entrar" element={<Login />} />
            <Route path="/apagar-conta" element={<PrivateRoute element={<ApagarConta />} />} />
        </Routes>
    )
}