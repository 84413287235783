import './style.css';

export default function Footer() {
    return (
        <footer className="bg-LightCyan text-grey pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h5>Links Úteis</h5>
                        <ul className="list-unstyled">
                            <li><a href="#home" className="text-grey">Home</a></li>
                            <li><a href="#about" className="text-grey">Conta</a></li>
                            <li><a href="#courses" className="text-grey">Oportunidades</a></li>
                            <li><a href="#contact" className="text-grey">Contato</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h5>Redes Sociais</h5>
                        <ul className="list-unstyled">
                            <li>
                                <a href="http://linkedin.com/company/inexapp" className="text-black">
                                    <i className="bi bi-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="text-center mt-4">
                        <p>&copy; 2024 Inex. Todos os direitos reservados.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}