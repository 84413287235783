import { api } from './axiosConfig';

export const listOportunidadesPaginate = async (page = 0, offset = 9, filter = {}) => {
  try {
    const { data } = await api.get(`/Oportunidade/listar`, {
      params: {
        page,
        offset,
        ...filter,
      },
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const getOportunidadeById = async (id) => {
    const response = await api.get(`/Oportunidade/${id}`);
    
    return response.data;
};
