import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.css';
import AlertMessage from '../../components/AlertMessage';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getOportunidadeById } from '../../services/oportunidadesService';
import useUser from '../../hooks/useUser';
import { CadastrarvisualizouOportunidade } from '../../services/visualizouOportunidadeService';

export default function OportunidadeDetalhe() {
    const { id } = useParams();
    const { isLogged, userDetails } = useUser();
    const navigate = useNavigate();
    const [oportunidade, setOportunidade] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOportunidade = async () => {
            try {
                if (!userDetails?.id && isLogged) {
                    return;
                }
                setLoading(true);
                const result = await getOportunidadeById(id);
                if (userDetails?.id) {
                    await CadastrarvisualizouOportunidade(id, userDetails?.id)
                }
                setOportunidade(result);
            } catch (error) {
                setAlertMessage({
                    title: error?.message || 'Erro ao carregar oportunidade',
                    message: error?.trace ? `Código: ${error?.trace}` : 'Erro desconhecido',
                    severity: 'error'
                });
            } finally {
                setLoading(false);
            }
        };

        fetchOportunidade();
    }, [id, userDetails,isLogged]);
    const handleApply = () => {
        const currentUrl = window.location.pathname;
        if (!isLogged) {
            navigate(`/entrar?redirect=${currentUrl}`);        
        } else {
            window.location.href = oportunidade?.linkRedirecionamento;
        }
    };

    if (loading) {
        return <p>Carregando...</p>;
    }

    if (!oportunidade) {
        return (
            <>
                {alertMessage?.title && <AlertMessage title={alertMessage?.title} message={alertMessage?.message} severity="error" />}
            </>
        );
    }

    return (
        <>
            <Header />
            <div className="oportunidade-detalhe-container bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="card shadow-lg my-5">
                                <div className="card-body">
                                    <h1 className="card-title">{oportunidade.titulo}</h1>
                                    <p className="text-muted">{new Date(oportunidade.dataCriacao).toLocaleDateString()}</p>
                                    <hr />
                                    <div className="oportunidade-details">
                                        <p className="card-text"><strong>Localidade:</strong> {oportunidade.regiaoNome}</p>
                                        <p className="card-text"><strong>Categoria:</strong> {oportunidade.tipoOportunidadeNome}</p>
                                        <p className="card-text"><strong>Expira em:</strong> {new Date(oportunidade.dataExpiracao).toLocaleDateString()}</p>
                                        <p className="card-text"><strong>Descrição:</strong> {oportunidade.descricao}</p>
                                    </div>
                                    <div className="mt-4">
                                        <button className="btn btn-primary" onClick={handleApply}>
                                            Aplicar para a Vaga
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
