import { api } from './axiosConfig';

export const CadastrarvisualizouOportunidade = async (oportunidadeId, usuarioId) => {
  try {
    const response = await api.post(`/VisualizouOportunidade`, {
      oportunidadeId,
      usuarioId,
    });

    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};
