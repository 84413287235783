import React, { useState, useEffect, useCallback } from 'react';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import './styles.css';
import { listOportunidadesPaginate } from '../../services/oportunidadesService';
import { listTipoOportunidades } from '../../services/tipoOportunidadeService';
import { listRegioes } from '../../services/regiaoService';
import AlertMessage from '../../components/AlertMessage';
import { useLocation } from 'react-router-dom';

export default function Oportunidades() {
    const [posts, setPosts] = useState([]);
    const [categoria, setCategoria] = useState('');
    const [subcategoria, setSubcategoria] = useState('');

    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(9);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    const [alertMessage, setAlertMessage] = useState(null);

    const totalPages = Math.ceil(count / offset);

    const [tiposOportunidades, setTiposOportunidades] = useState([]);
    const [regiao, setRegiao] = useState([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const tipoOportunidade = queryParams.get('tipo_oportunidade');


    const fetchOportunidades = useCallback(async () => {
        try {
            setLoading(true);
            
            const categoriaInicial = tipoOportunidade === 'extensao' ? '1' : tipoOportunidade === 'iniciacao' ? '2' : null;
            
            const params = {
                tipoOportunidadeId: categoria ? categoria : categoriaInicial,
                regiaoId: subcategoria ? subcategoria : undefined,
            };            

            const [oportunidadesResult, tiposResult, regioesResult] = await Promise.all([
                listOportunidadesPaginate(page, offset, params),
                listTipoOportunidades(),
                listRegioes()
            ]);

            setPosts(oportunidadesResult.items);
            setCount(oportunidadesResult.totalCount);
            setTiposOportunidades(tiposResult);
            setRegiao(regioesResult);
        } catch (error) {
            setAlertMessage({
                title: error?.message || 'Erro Genérico',
                message: error?.trace && `Código: ${error?.trace}`,
            });
        } finally {
            setLoading(false);
        }
    }, [page, offset, categoria, subcategoria]);

    useEffect(() => {      
        fetchOportunidades();
    }, [fetchOportunidades]);

    const handleNextPage = () => {
        if (page < totalPages - 1) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    return (
        <>
            <Header />
            <div className="bg-gray-100 text-gray-800">
                {alertMessage?.title && (<AlertMessage title={alertMessage?.title} message={alertMessage?.message} severity={alertMessage?.severity} />
                )}
                {/* Filtro em Cascata */}
                <div className="container mt-5">
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <select
                                id="categoria-principal"
                                className="form-select"
                                value={categoria}
                                onChange={(e) => setCategoria(e.target.value)}
                            >
                                <option value="all">Todas as Categorias</option>
                                {tiposOportunidades?.map(tipo => (
                                    <option key={tipo?.id} value={tipo?.id}>{tipo?.nome}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-3">
                            <select
                                id="regiao"
                                className="form-select"
                                value={subcategoria}
                                onChange={(e) => setSubcategoria(e.target.value)}
                            >
                                <option value="all">Todas as Regiões</option>
                                {regiao?.map(reg => (
                                    <option key={reg.id} value={reg.id}>{reg.nome}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Resultados dos Posts */}
                    <div id="posts-container" className="row">
                        {loading ? (
                            <p>Carregando...</p>
                        ) : (
                            posts?.map((post) => (
                                <div key={post.id} className="col-md-4 mb-4">
                                    <div className="card h-100 shadow-sm">
                                        <div className="card-body d-flex flex-column">
                                            <a href={`/oportunidades/${post?.id}`} style={{ textDecoration: 'none' }}>
                                                <h5 className="card-title" onClick={() => { window.location.href = `/oportunidades/${post?.id}` }}>{post.titulo}</h5>
                                            </a>
                                            <p className="card-text">
                                                {post.descricao?.length > 100 ? `${post.descricao.slice(0, 100)}...` : post.descricao}
                                            </p>

                                            <p className="card-text text-muted">
                                                Data de Criação: {new Date(post.dataCriacao).toLocaleDateString()}
                                            </p>
                                            <p className="card-text text-muted">
                                                Expira em: {new Date(post.dataExpiracao).toLocaleDateString()}
                                            </p>
                                            <div className="mt-auto">
                                                <span className="badge bg-primary">{post.regiaoNome}</span>{' '}
                                                <span className="badge bg-success">{post.tipoOportunidadeNome}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>


                    {/* Botões de paginação */}
                    <div className="pagination mt-3">
                        <button
                            className="btn btn-primary"
                            onClick={handlePreviousPage}
                            disabled={page === 0}
                        >
                            Anterior
                        </button>
                        <span className="mx-3">Página {page + 1} de {totalPages}</span>
                        <button
                            className="btn btn-primary"
                            onClick={handleNextPage}
                            disabled={page >= totalPages - 1}
                        >
                            Próximo
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
